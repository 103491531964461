/**
 * Gets the path of a nested property
 */
export type NestedPath<T extends object, Depth extends number = 10> = [Depth] extends [never]
  ? never
  : {
      [K in keyof T & string]: T[K] extends object ? K | `${K}.${NestedPath<T[K], Prev[Depth]>}` : K;
    }[keyof T & string];
// used to prevent infinite recursion
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

/**
 * Near-identical type (with some minor tweaks) pulled from @shopify/app-bridge
 * Gets the value type for a given path in an object type
 */
export type PathValue<T, Path extends string> = Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? Rest extends string
      ? PathValue<NonNullable<T[Key]>, Rest>
      : never
    : never
  : Path extends keyof T
    ? T[Path] extends object
      ? Partial<T[Path]>
      : T[Path]
    : never;
/**
 * Sets values of nested properties
 * Ex. modernThemeSettings.sections.banner.display
 */
export const setNestedProperty = <T extends object, P extends NestedPath<T>, V extends PathValue<T, P>>(
  obj: T,
  path: P,
  value: V
) => {
  const keys = path.split(".") as Array<keyof T>;
  let current: any = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }

  current[keys[keys.length - 1]] = value;
  return obj;
};
